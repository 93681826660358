import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { langs } from '@/constants/langs'

Vue.use(VueI18n)

function loadFrMessages() {
  const locales = require.context('./traductions/fr-FR', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadRoMessages() {
  const locales = require.context('./traductions/ro-RO', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadEnMessages() {
  const locales = require.context('./traductions/en-US', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadDeMessages() {
  const locales = require.context('./traductions/de-DE', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadNoMessages() {
  const locales = require.context('./traductions/nb-NO', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadFiMessages() {
  const locales = require.context('./traductions/fi-FI', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadSvMessages() {
  const locales = require.context('./traductions/sv-SE', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadJaMessages() {
  const locales = require.context('./traductions/ja-JP', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadLvMessages() {
  const locales = require.context('./traductions/lv-LV', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadDkMessages() {
  const locales = require.context('./traductions/da-DK', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadItMessages() {
  const locales = require.context('./traductions/it-IT', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadLtMessages() {
  const locales = require.context('./traductions/lt-LT', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadNlMessages() {
  const locales = require.context('./traductions/nl-NL', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}


function loadCZMessages() {
  const locales = require.context('./traductions/cs-CZ', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadUKMessages() {
  const locales = require.context('./traductions/en-UK', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadESMessages() {
  const locales = require.context('./traductions/es-ES', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadLocaleMessages(locales) {
  let messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      messages = {
        ...messages,
        ...locales(key)
      }
      return
    }
  })
  return messages
}



export default new VueI18n({
  locale: langs.find(lang => lang.locale == window.navigator.language) ? window.navigator.language : 'en-US',
  fallbackLocale: langs.find(lang => lang.locale == window.navigator.language) ? window.navigator.language : 'en-US', 
  messages: {
    'fr-FR': loadFrMessages(),
    'en-US': loadEnMessages(),
    'ro-RO': loadRoMessages(),
    'de-DE': loadDeMessages(),
    'nb-NO': loadNoMessages(),
    'fi-FI': loadFiMessages(),
    'sv-SE': loadSvMessages(),
    'ja-JP': loadJaMessages(),
    'lv-LV': loadLvMessages(),
    'da-DK': loadDkMessages(),
    'it-IT': loadItMessages(),
    'lt-LT': loadLtMessages(),
    'nl-NL': loadNlMessages(),
    'cs-CZ': loadCZMessages(),
    'en-UK': loadUKMessages(),
    'es-ES': loadESMessages()
  }
})
